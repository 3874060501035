<template>
  <div>
    <b-card-code>
      <b-card-text class="mb-0">
        <h3>{{ roleName }}</h3>
        <span>Assigned Permissions</span>
      </b-card-text>
      <div class="demo-inline-spacing">
        <!-- all permissions of role showed to delete -->
        <div v-for="(permission, index) in roleAssignedPermission" :key="index">
          <b-button
            variant="outline-danger"
            @click="deletePer(permission.id, permission.name)"
          >
            <span class="align-middle">{{ permission.name }}</span>
            <feather-icon icon="XIcon" class="ml-50" />
          </b-button>
        </div>
      </div>
    </b-card-code>
    <!-- success handelr -->
    <b-alert
      v-height-fade.appear
      fade
      :show="showSuccessleAlert"
      @dismissed="showSuccessAlert = false"
      variant="success"
    >
      <h4 class="alert-heading">
        Success
      </h4>
      <div class="alert-body">
        <span>{{ success_message }}</span>
      </div>
    </b-alert>
    <!-- error handelr -->
    <b-alert
      v-height-fade.appear
      fade
      :show="showDismissibleAlert"
      @dismissed="showDismissibleAlert = false"
      variant="danger"
    >
      <h4 class="alert-heading">
        Alert
      </h4>
      <div class="alert-body">
        <ul v-for="(values, index) in errors_back" :key="index">
          <li v-for="(value, indexVal) in values" :key="indexVal">
            {{ value }}
          </li>
        </ul>
      </div>
    </b-alert>
  </div>
</template>

<script>

import BCardCode from '@core/components/b-card-code'

export default {
  components: {
    BCardCode,
  },
  
  data() {
    return {
      id: 0,
      roleName: '',
      roleAssignedPermission: [],
      success_message: '',
      showDismissibleAlert: false,
      showSuccessleAlert: false,
      errors_back: [],
      errors_assign: {},
      option: [],
      dir: 'ltr',
      selected: [],
      permissions: [],
    }
  },
  methods: {
    // deletePer function to delete revoke permission from role
    deletePer(id, name) {
      this.permissions.push(id)
      this.$bvModal
        .msgBoxConfirm(
          'Please confirm that you want to delete (' + name + ').',
          {
            title: 'Are you sure?',
            size: 'sm',
            okVariant: 'danger',
            okTitle: 'Delete',
            cancelTitle: 'Cancel',
            cancelVariant: 'outline-secondary',
            hideHeaderClose: false,
            centered: true,
          }
        )
        .then((value) => {
          if (value) {
            axios
              .post('role/permissions/delete', {
                role: this.id,
                permissions: this.permissions,
              })
              .then((result) => {
                this.$swal({
                  position: 'center',
                  icon: 'success',
                  title: 'Your work has been saved',
                  showConfirmButton: false,
                  timer: 1500,
                })
                location.reload()
              })
              .catch((err) => {
                this.$swal({
                  position: 'center',
                  icon: 'error',
                  title: 'Error!',
                  showConfirmButton: false,
                  timer: 1500,
                })
                this.errors_back.length = 0
                if (err.response.data.data != null) {
                  if (this.isString(err.response.data.data)) {
                    this.errors_back.push({
                      error: err.response.data.data,
                    })
                  } else {
                    this.errors_back = err.response.data.data
                  }
                  this.showDismissibleAlert = true
                } else {
                  this.errors_back = []
                  this.errors_back.push({
                    error: 'internal server error',
                  })
                  this.showDismissibleAlert = true
                }
              })
          }
        })
    },
    isString(value) {
      return typeof value === 'string' || value instanceof String
    },
  },
  created() {
    // request role data
    this.id = this.$route.params.id
    axios
      .get('role/' + this.id)
      .then((result) => {
        const data = result.data.data
        this.roleName = data.name
        for (let index in data.permissions) {
          this.roleAssignedPermission.push({
            id: data.permissions[index].id,
            name: data.permissions[index].name,
          })
        }
      })
      .catch((err) => {
        this.errors_back.length = 0
        if (err.response.data.data != null) {
          if (this.isString(err.response.data.data)) {
            this.errors_back.push({
              error: err.response.data.data,
            })
          } else {
            this.errors_back = err.response.data.data
          }
          this.showDismissibleAlert = true
        } else {
          this.errors_back = []
          this.errors_back.push({
            error: 'internal server error',
          })
          this.showDismissibleAlert = true
        }
      })
    // request all permissions data
    axios
      .get('permission/index')
      .then((result) => {
        const data = result.data.data
        for (let index in data) {
          if (!this.roleAssignedPermission.includes(data[index].name)) {
            this.option.push({
              value: data[index].id,
              text: data[index].name,
            })
          }
        }
      })
      .catch((err) => {
        this.errors_back.length = 0
        if (err.response.data.data != null) {
          if (this.isString(err.response.data.data)) {
            this.errors_back.push({
              error: err.response.data.data,
            })
          } else {
            this.errors_back = err.response.data.data
          }
          this.showDismissibleAlert = true
        } else {
          this.errors_back = []
          this.errors_back.push({
            error: 'internal server error',
          })
          this.showDismissibleAlert = true
        }
      })
  },
}
</script>

<style lang="scss">
 
</style>
